<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->

    <!-- Input Column -->
    <v-col cols="12" md="12" class="pa-0">
      <v-sheet color="white" elevation="3" rounded class="mr-1 pb-5 column-content d-flex align-center justify-center" style="min-height: 90vh">
        <h4>Page Under Development</h4>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UserSubscriptions",
  data: () => {
    return {
      requestSent: false,
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {},
  async mounted() {
    // await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        // getting values from store, database or route
        // initializing local variables and functions
        // console.log("getuser: ", this.$store.getters.getUser);
        // await this.function_template();
      } catch (error) {}
    },

    async function_template() {
      try {
        // validating input
        // setting component loading
        // sending request
        // handling response
        // handling error
        // await new Promise((r) => setTimeout(r, 200));
        // const res = await this.axios.post("/api/content/gethistory", { options: this.options, page: "blog" });
        // if (!res.data.success) throw new Error(res.data.message);
        // this.history = res.data.history;
      } catch (error) {
        // console.log(error);
        // this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },
  },
};
</script>

<style scoped></style>
