<template>
  <!-- View Layout -->
  <v-row
    class="d-flex flex-row justify-space-between align-start ma-0 pa-0"
    align="stretch"
  >
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="-1"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-col cols="12" class="pa-0 d-flex">
      <v-sheet
        color="white"
        elevation="3"
        rounded
        class="mr-md-1 pb-3 mx-0 column-content w-100"
        style="min-height: 90vh"
      >
        <v-row class="px-2 mt-6 pb-0 justify-space-between" no-gutters>
          <v-col
            cols="12"
            md="12"
            class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
              height="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">Enter Search Data</h5>
              </div>

              <v-form @submit.prevent="submitForm" ref="form">
                <!-- Section 1: Search Fields (Box 1) -->
                <v-card class="mb-4" elevation="2" rounded>
                  <v-card-title class="text-left">Search Fields</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Name Input -->
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.name"
                          label="Search Name"
                          class="small-font lc-input-fields"
                          dense
                          solo
                          :rules="[(v) => !!v || 'Search Name is required']"
                        >
                          <v-tooltip color="bssecondary2" bottom slot="append">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="bssecondary2"
                                dense
                                small
                                v-bind="attrs"
                                v-on="on"
                                >mdi-information-variant</v-icon
                              >
                            </template>
                            <span v-html="'Give this search a name!'"></span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>

                      <!-- URL Input -->
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.url"
                          label="Search URL"
                          class="small-font lc-input-fields"
                          :rules="urlRules"
                          dense
                          solo
                        >
                          <v-tooltip color="bssecondary2" bottom slot="append">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="bssecondary2"
                                dense
                                small
                                v-bind="attrs"
                                v-on="on"
                                >mdi-information-variant</v-icon
                              >
                            </template>
                            <span
                              v-html="
                                'Add Linkedin Sales Navigator search people url!'
                              "
                            ></span>
                          </v-tooltip>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- Section 2: Other Fields (Box 2) -->
                <v-card class="mb-4" elevation="2" rounded>
                  <v-card-title class="text-left"
                    >Additional Fields</v-card-title
                  >
                  <v-card-text>
                    <!-- First Row: 4 Fields (Job Title, Location, Education, Tenure) -->
                    <v-row no-gutters>
                      <!-- Job Title -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.jobTitle"
                          label="Job Title *"
                          class="small-font lc-input-fields"
                          dense
                          solo
                          :rules="[(v) => !!v || 'Job Title is required']"
                        ></v-text-field>
                      </v-col>

                      <!-- Location -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.location1"
                          label="Location *"
                          class="small-font lc-input-fields"
                          dense
                          solo
                          :rules="[(v) => !!v || 'location is required']"
                        ></v-text-field>
                      </v-col>

                      <!-- Education -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.education1"
                          label="Education"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Tenure -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.tenure1"
                          label="Tenure"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- Second Row: 3 Fields (Company Details, Technical Skills, Keywords) and 1 Empty Column -->
                    <v-row no-gutters>
                      <!-- Company Details -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.companyDetails1"
                          label="Company Details"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Technical Skills -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.technicalSkills1"
                          label="Technical Skills"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Keywords -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model="form.keywords1"
                          label="Keywords"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Empty Column for Space -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <!-- Empty Column to leave space after Keywords -->
                      </v-col>
                    </v-row>

                    <!-- Third Row: Relevant Experience Max First, then Other Fields -->
                    <v-row no-gutters>
                      <!-- Relevant Experience Max (First Field in this Row) -->
                   

                      <!-- Relevant Experience Min -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.relaventExperienceMin1"
                          label="Relevant Experience Min"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.relaventExperienceMax1"
                          label="Relevant Experience Max"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.experienceMin1"
                          label="Experience Min"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Experience Max -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.experienceMax1"
                          label="Experience Max"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- Fourth Row: Experience Max, Additional Information -->
                    <v-row no-gutters>
                      <!-- Experience Min -->
                      <!-- Company Size Min -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.companySizeMin1"
                          label="Company Size Min"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Company Size Max -->
                      <v-col cols="12" sm="6" md="3" class="pa-1">
                        <v-text-field
                          v-model.number="form.companySizeMax1"
                          label="Company Size Max"
                          type="number"
                          class="small-font lc-input-fields"
                          dense
                          solo
                        ></v-text-field>
                      </v-col>

                      <!-- Additional Information (Last Column) -->
                      <v-col cols="12" sm="6" md="9" class="pa-1">
                        <v-textarea
                          v-model="form.jobDescriptionText"
                          label="Additional Information"
                          class="small-font lc-input-fields"
                          dense
                          solo
                          :cols="2"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- Submit Button -->
                <v-btn
                  :disabled="isSearchLoading"
                  type="submit"
                  class="my-1 ml-4"
                  color="white"
                  small
                  elevation="2"
                  style="text-transform: capitalize"
                >
                  <v-progress-circular
                    v-if="isSearchLoading"
                    indeterminate
                    :size="18"
                    :width="2"
                    color="blue-grey"
                    class="mr-2"
                  ></v-progress-circular>
                  <v-icon v-if="!isSearchLoading" class="mr-1" small
                    >mdi-check</v-icon
                  >
                  <span v-if="!isSearchLoading">Submit</span>
                  <span v-else>Processing...</span>
                </v-btn>

                <div v-if="validationError" class="error-message">
                  {{ validationError }}
                </div>
                <div v-if="successMessage" class="success-message">
                  {{ successMessage }}
                </div>
              </v-form>
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0 d-flex flex-column align-center justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">Include / Exclude Criteria</h5>
              </div>

              <v-form ref="criteriaForm">
                <v-row>
                  <!-- Top 3 switches visible by default -->
                  <v-col
                    v-for="(item, index) in criteriaSwitches.slice(0, 4)"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="3"
                    lg="2"
                  >
                    <div class="d-flex flex-wrap align-center">
                      <v-switch
                        v-model="item.value"
                        class="ml-4"
                        color="bssecondary2"
                      ></v-switch>
                      <span class="v-label">{{ item.label }}</span>
                    </div>
                  </v-col>

                  <!-- Advanced toggles section -->
                  <v-col cols="12" class="pt-0">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="v-label">
                          Advanced Options
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col
                              v-for="(item, index) in criteriaSwitches.slice(4)"
                              :key="index"
                              cols="12"
                              sm="6"
                              md="3"
                              lg="2"
                            >
                              <div class="d-flex flex-wrap align-center">
                                <v-switch
                                  v-model="item.value"
                                  class="ml-4"
                                  color="bssecondary2"
                                ></v-switch>
                                <span class="v-label">{{ item.label }}</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>

          <v-col cols="12" md="12" class="mr-0 pr-0 py-0 settings-div">
            <v-sheet
              color="white"
              elevation="3"
              rounded
              class="d-flex align-start justify-center mt-2"
            >
              <v-data-table
                v-model:expanded="expanded"
                :headers="headers"
                :items="filteredItems"
                item-key="id"
                dense
                show-expand
                class="px-3 py-4 fill-height w-100"
                :footer-props="{
                  'items-per-page-options': [20, 30, 50, 100, 250],
                }"
              >
                <template v-slot:top>
                  <v-row
                    class="px-4 py-1 align-baseline"
                    no-gutters
                    justify="space-between"
                  >
                    <!-- Heading and Switch in One Column -->
                    <v-col
                      cols="12"
                      class="d-flex align-center justify-space-between mx-0 px-0 py-0"
                    >
                      <h4 class="my-2 mx-0 text-left pr-2">
                        <span>Scoring Results</span>
                      </h4>
                      <div class="d-flex align-center justify-space-between">
                        <template>
                          <div class="mx-6">
                            <v-btn
                              v-if="
                                (isEliminatedToggle &&
                                  eliminatedProfiles.length > 0) ||
                                filterScoringData.length > 0
                              "
                              @click="downloadExcel"
                              class="my-1 ml-4"
                              color="white"
                              small
                              elevation="2"
                              style="text-transform: capitalize"
                            >
                              Download Excel
                            </v-btn>
                          </div>
                        </template>
                        <div
                          class="d-flex ga-6 flex-wrap align-center"
                          v-if="eliminatedProfiles.length > 0"
                        >
                          <v-switch
                            v-model="isEliminatedToggle"
                            color="bssecondary2"
                          ></v-switch>
                          <span class="v-label">Include rejected profiles</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.profile_url="{ item }">
                  <a :href="item.profile_url" target="_blank">Profile</a>
                </template>

                <template v-slot:item.total_score="{ item }">
                  {{ ` ${item.total_score} / ${item.outOfTotal}` }}
                </template>

                <template v-slot:item.score_percentage="{ item }">
                  {{ item.score_percentage }}%
                </template>

                <template v-slot:item.accepted="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ item.accepted ? "No" : "Yes" }}
                        <!-- Display the score -->
                      </div>
                    </template>
                    <span>{{ item.acceptedRemarks }}</span>
                    <!-- Tooltip text containing the remarks -->
                  </v-tooltip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card flat class="my-2">
                      <v-card-text>
                        <score-details
                          :data="item.scoring"
                          :criteriaSwitches="criteriaSwitches"
                        ></score-details>
                      </v-card-text>
                    </v-card>
                  </td>
                </template>

                <template v-slot:no-data>
                  {{
                    isSearchLoading || isContentLoading
                      ? "Loading data..."
                      : filterScoringData.length === 0 &&
                        eliminatedProfiles.length > 0
                      ? "No profiles meet the criteria"
                      : "No records found"
                  }}
                </template>
              </v-data-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ScoreDetails from "./ScoreDetails.vue";
import axios from "axios";
import config from "../../../../config/app.json";
import XLSX from "xlsx-js-style";

export default {
  components: {
    ScoreDetails,
  },
  props: ["id"],
  async created() {
    if (this.id) {
      const history = this.historyData.find((f) => f.id === this.id);
      const toggles = history.toggles;

      if (toggles) {
        this.criteriaSwitches = this.criteriaSwitches.map((switchItem) => {
          if (toggles.hasOwnProperty(switchItem.key)) {
            switchItem.value = toggles[switchItem.key];
          }
          return switchItem;
        });
      }
      this.form.url = history.searchUrl;
      this.form.name = history.searchName;
      if (history.jobDescriptionText) {
        this.form.jobDescriptionText = history.jobDescriptionText;
        this.form.jobDescriptionType = "Text Area";
      }
      await this.scoringProfilesWithJobDescId(history.job_description_id);
    }
  },
  data() {
    return {
      form: {
        name: "",
        url: "",
        jobTitle: "",
        experienceMin1: null,
        experienceMax1: null,
        companySizeMax1: null,
        companySizeMin1: null,
        location1: "",
        education1: "",
        jobDescriptionType: "Text Area", // 'File' or 'Text'
        jobDescriptionText: "",
        tenure1: "",
        companyDetails1: "",
        technicalSkills1: "",
        keywords1: "",
        additionalInformation1: "",
        relavantExperienceMin1: null,
        relavantExperienceMax1: null,
      },

      urlRules: [
        (v) => !!v || "URL is required",
        (v) => /https?:\/\/.+/.test(v) || "Must be a valid URL",
      ],
      fileRules: [
        (v) => !!v || "File is required",
        (v) =>
          !v ||
          (v &&
            [
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(v.type)) ||
          "Only PDF and DOCX files are allowed",
      ],
      textRules: [(v) => !!v || "Job description cannot be empty"],
      successMessage: "",
      validationError: "",
      expanded: [],
      headers: [
        { text: "Name", value: "name", align: "start", sortable: false },
        { text: "Job Title", value: "jobTitle" },
        { text: "Years of Experince", value: "yearsOfExperience" },
        { text: "Total Score", value: "total_score" },
        { text: "Percentage", value: "score_percentage" },
        { text: "Rejected", value: "accepted" },
        { text: "Link", value: "profile_url" },
        { text: "", value: "data-table-expand" },
      ],

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      scoringData: [],
      filterScoringData: [],
      eliminatedProfiles: [],
      isEliminatedToggle: false,
      isContentLoading: false,

      // Initialize all switches to true
      criteriaSwitches: [
        { label: "Job Title", value: true, key: "jobTitle" },
        // { label: "Job Type", value: true, key: "jobType" },
        {
          label: "Relevant Experience",
          value: true,
          key: "relevantExperience",
        },
        { label: "Education", value: true, key: "education" },
        {
          label: "Company Deatils",
          value: true,
          key: "companyKnowledge",
        },
        { label: "Tenure", value: true, key: "tenure" },
        { label: "Location", value: true, key: "location" },
        {
          label: "Company Size",
          value: true,
          key: "companySize",
        },
        { label: "Years of Experience", value: true, key: "overallExperience" },
        {
          label: "Technical Skills ",
          value: true,
          key: "technicalSkills",
        },
        // {
        //   label: "Soft Skills ",
        //   value: true,
        //   key: "softSkills",
        // },
        {
          label: "Keywords",
          value: true,
          key: "keywords",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      isSearchLoading: (state) => state.scorings.isSearchLoading,
      getUser: (state) => state.users.user,
      historyData: (state) => state.scorings.historyData,
      searchResults: (state) => state.scorings.searchResults,
      searchError: (state) => state.scorings.searchError,
      filteredItems() {
        return this.isEliminatedToggle
          ? [...this.filterScoringData, ...this.eliminatedProfiles]
          : this.filterScoringData;
      },
    }),
  },

  watch: {
    criteriaSwitches: {
      handler(newVal) {
        const data = this.FilterScoringData(
          this.scoringData.filter((f) => f.accepted),
          newVal
        );
        const eliminatedData = this.FilterScoringData(
          this.scoringData.filter((f) => !f.accepted),
          newVal
        );

        this.setFilterScoringData(data);
        this.setEliminatedData(eliminatedData);
      },
      deep: true,
    },

    searchResults(newResults) {
      this.scoringData = newResults;
      console.log("search result",   this.scoringData)
      this.setFilterScoringData(
        this.FilterScoringData(
          newResults.filter((f) => f.accepted),
          this.criteriaSwitches
        )
      );
      this.setEliminatedData(
        this.FilterScoringData(
          newResults.filter((f) => !f.accepted),
          this.criteriaSwitches
        )
      );
      this.snackbarShow(true, "Scoring Results Successfully");
      this.successMessage = "Your search has been completed";
    },

    searchError(err) {
      if (err.response.status === 400) {
        this.validationError = err.response.data?.message;
      }

      if (err.response.status == 429) {
        this.snackbarShow(true, "Error", err.response.data?.message || "");
        this.validationError = err.response.data?.message || "";
      } else if (err.response.status == 401) {
        this.snackbarShow(
          true,
          "Error",
          "Please Login your linkedin account first"
        );
        this.validationError =
          "Please Login your linkedin account first";
      } else {
        this.snackbarShow(
          true,
          "Error",
          err.message
            ? err.message
            : "Could not Search Profiles, try refreshing the page and starting again"
        );
        this.validationError = err.message
          ? err.message
          : "Could not Search Profiles, try refreshing the page and starting again";
      }
    },
  },
  mounted() {
    this.setSearchError(null);
  },
  methods: {
    ...mapActions({
      submitSearch: "submitSearch",
      setSearchError: "setSearchError",
    }),

    setScoringData(data) {
      this.scoringData = data;
    },

    setFilterScoringData(data) {
      this.filterScoringData = data;
    },
    setEliminatedData(data) {
      this.eliminatedProfiles = data;
    },

    // Scoring Section Filter
    FilterScoringData(defaultData, filterArray) {
      if (!defaultData || defaultData.length === 0) return [];

      if (!filterArray || filterArray.length === 0) return defaultData;

      const removeKeys = [
        "softSkills",
        "engagement",
        "otherRequirements",
        "jobType",
        "companyReputation",
      ];

      const keysToDisable = new Set(
        filterArray.filter((f) => !f.value).map((f) => f.key)
      );

      return defaultData.map((item) => {
        let total_score = 0;
        let outOfTotal = 0;
        

        const newScoring = Object.entries(item.scoring).reduce(
          (acc, [key, category]) => {
            if (!keysToDisable.has(key) && !removeKeys.includes(key)) {
              acc[key] = category;
              if (typeof category === "object") {
               
                var keysArr = Object.keys(category).filter(
                  (f) => !f.includes("details") && !f.includes("remarks")
                 );
                console.log("key Arr==========>",keysArr)
                outOfTotal += keysArr.length * 10;
                total_score += keysArr.reduce(
                  (sum, key) => sum + category[key],
                  0
                );
              } else {
                outOfTotal += 10;
                total_score += category;
              }
            }
            return acc;
          },
          {}
        );

        return {
          ...item,
          scoring: newScoring,
          outOfTotal,
          total_score: total_score.toFixed(2),
          score_percentage: ((total_score / outOfTotal) * 100 || 0).toFixed(2),
        };
      });
    },

    async submitForm() {
      // Clear previous messages
      this.successMessage = "";
      this.validationError = "";
console.log(this.form)
      // Validate the form
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.validationError = "Please fix the errors above before submitting.";
        return;
      }

      // Check required fields (example)
      if (!this.form.jobTitle) {
        this.validationError = "Job Title is required.";
        return;
      }

    

      // Prepare form data for submission
      const formData = new FormData();
      formData.append("searchURL", this.form.url);
      formData.append("user_id", this.$store.getters.getUser.id);
      formData.append("searchName", this.form.name || "No Name");

      // Append new form fields
      formData.append("jobTitle", this.form.jobTitle);
      formData.append("experienceMin1", this.form.experienceMin1);
      formData.append("experienceMax1", this.form.experienceMax1);
      formData.append("companySizeMin1", this.form.companySizeMin1);
      formData.append("companySizeMax1", this.form.companySizeMax1);
      formData.append("additionalInformation1", this.form.additionalInformation);
      formData.append("relaventExperienceMin1", this.form.relaventExperienceMin1);
      formData.append("relaventExperienceMax1", this.form.relaventExperienceMax1);
      formData.append("technicalSkills1", this.form.technicalSkills1);
      formData.append("keywords1", this.form.keywords1);
      formData.append("tenure1", this.form.tenure1);
      formData.append("companyDetails1", this.form.companyDetails1);
      formData.append("location1", this.form.location1 || "Any");
      formData.append("education1", this.form.education1 || "Any");
      formData.append("jobDescriptionText", this.form.jobDescriptionText);
      formData.append("jobDescriptionType", this.form.jobDescriptionType);

      // Append criteria switches if any
      this.criteriaSwitches.forEach((v) => {
        formData.append(v.key, v.value);
      });

      try {
        this.snackbarShow(
          true,
          "This may take some time depending on the data size; you'll receive an email once the search is complete, and then you can check your result in Scoring History."
        );
        this.scoringData = [];
        this.filterScoringData = [];
        this.eliminatedProfiles = [];

        // Submit search form data
        await this.submitSearch(formData);
        this.successMessage = "Search submitted successfully!";
      } catch (error) {
        console.error("Error:", error.response);
        this.validationError =
          "An error occurred during submission. Please try again.";
      }
    },

    async scoringProfilesWithJobDescId(desc_id) {
      try {
        this.isContentLoading = true;
        const response = await axios.get(
          `${config.API_URL}/api/linkedin/scoringprofiles`,
          {
            params: { job_description_id: desc_id },
          }
        );

        // const jobDesp = await axios.get(
        //   `${config.API_URL}/api/linkedin/getJobDescriptionById`,
        //   {
        //     params: { Id: desc_id },
        //   }
        // );
        // const desp = jobDesp.data.response;

        const data = response.data.response;
        if (data && data.length > 0) {
          const realData = data.map((m) => ({
            ...m,
            jobTitle: m.linkedin_profile.subtitle,
            yearsOfExperience: m.yearsOfExperience.toFixed(2),
            scoring:
              typeof m.scoring == "string" ? JSON.parse(m.scoring) : m.scoring,
          }));
          this.setScoringData(realData);

          this.setFilterScoringData(
            this.FilterScoringData(
              realData.filter((f) => f.accepted),
              this.criteriaSwitches
            )
          );
          this.setEliminatedData(
            this.FilterScoringData(
              realData.filter((f) => !f.accepted),
              this.criteriaSwitches
            )
          );
          this.isContentLoading = false;

          this.snackbarShow(true, "Getting Scoring Results Successfully");
          this.successMessage = "Getting Scoring Results Successfully";
        }
      } catch (error) {
        console.error("Error:", error);
        this.isContentLoading = false;
        this.snackbarShow(
          true,
          "Error",
          error.message
            ? error.message
            : "Could not find the history of scoring profiles"
        );
      }
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    downloadExcel() {
      // Define your custom headers and data
      const headers = [
        "Job Description",
        "profiles urls",
        "profile name",
        "title (system)",
        "loc (system)",
        "rel exp (system)",
        "yrs exp (system)",
        "edu (system)",
        "tech skills (system)",
        "tenure (system)",
        "keywords (system)",
        "company size (system)",
        "Total (System)",
        "Status",
        "Reason",
      ];
      const exportData = this.isEliminatedToggle
        ? [...this.filterScoringData, ...this.eliminatedProfiles]
        : this.filterScoringData;
      // Prepare your data with the custom headers


      const formattedData = exportData.map((item) => {
        const {
          jobTitle,
          location,
          relevantExperience,
          overallExperience,
          tenure,
          education,
          technicalSkills,
          companySize,
          keywords,
        } = item.scoring;
        console.log(jobTitle.score)
        return {
          "Job Description": this.form.jobDescriptionText,
          "profiles urls": item.profile_url,
          "profile name": item.name,
          "title (system)": jobTitle?jobTitle.score:0 ,
          "loc (system)":location? location.score:0,
          "rel exp (system)":relevantExperience? relevantExperience.score:0 ,
          "yrs exp (system)":overallExperience? overallExperience.score:0,
          "edu (system)": education?education.score:0,
          "tech skills (system)":technicalSkills? technicalSkills.score:0 ,
          "tenure (system)":tenure? tenure.score:0 ,
          "keywords (system)":keywords? keywords.score:0 ,
          "company size (system)":companySize? companySize.score:0,
          "Total (System)": item.total_score,
          Status: item.accepted ? "Pass" : "Fail",
          Reason: item.acceptedRemarks,
        };
      });

      // Convert data to worksheet format
      const worksheet = XLSX.utils.json_to_sheet(formattedData, {
        header: headers,
      });

      // Merge cells for "Job Description"
      const lastRow = formattedData.length; // Get the total number of rows
      worksheet["!merges"] = [
        { s: { r: 1, c: 0 }, e: { r: lastRow, c: 0 } }, // Merging "Job Description" cells from row 1 to last row
      ];

      // Apply custom styles to the header row
      const headerStyle = {
        font: { bold: true }, // Bold font
        alignment: { horizontal: "center" }, // Center alignment
      };

      // Apply style to each header field
      headers.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 }); // 0 = header row
        if (!worksheet[cellAddress]) worksheet[cellAddress] = {};
        worksheet[cellAddress].s = headerStyle;
      });

      // Dynamically set column widths based on the content length
      const colWidths = headers.map((header, index) => {
        if (index === 0) return { wch: header.length + 15 };
        const maxLength = Math.max(
          header.length,
          ...formattedData.map((row) =>
            row[header] ? row[header].toString().length : 0
          )
        );
        return { wch: maxLength + 5 }; // Adjust +5 to give some padding
      });
      worksheet["!cols"] = colWidths;

      // Create a workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Create a binary string and trigger the download
      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const blob = new Blob([this.s2ab(excelData)], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);

      // Create a link element for downloading
      const a = document.createElement("a");
      a.href = url;
      a.download = "linkedin_profiler.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length); // Convert to an ArrayBuffer
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bd1-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bd2-base) !important;
}

.tooltip-text {
  color: black;
}
.chatbot-settings-wrapper .v-btn {
  text-transform: capitalize;
  min-width: 200px;
  float: right;
}

.tabs-main {
  border-radius: 3px;
  margin-bottom: 5px;
}
.v-tabs .v-tab {
  color: #ffffff !important; /* Navy color */
}
.error-message {
  color: red;
  margin-top: 10px;
}
.success-message {
  font-weight: bold;
  font-size: 13px;
  padding-right: 20px;
  text-align: right;
}
.submit-button {
  margin-top: 20px;
}

.login-button.success {
  background-color: green;
  color: white;
}
</style>
