import axios from "axios";
import config from "../../config/app.json";

export const scorings = {
  state: () => ({
    historyData: [],
    historyFilters: {
      createdAt: "",
      searchName: "",
    },
    searchResults: [],
    searchError: null,
    isSearchLoading: false,
  }),
  getters: {
    historyData: (state) => state.historyData,
    historyFilters: (state) => state.historyFilters,
    searchResults: (state) => state.searchResults,
    isSearchLoading: (state) => state.isSearchLoading,
    searchError: (state) => state.searchError,
  },
  mutations: {
    SET_HISTORY_DATA(state, data) {
      state.historyData = data;
    },
    SET_HISTORY_FILTERS(state, filters) {
      state.historyFilters = filters;
    },
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results;
    },
    SET_SEARCH_LOADING(state, isLoading) {
      state.isSearchLoading = isLoading;
    },
    SET_ERROR(state, error) {
      state.searchError = error;
    },
  },
  actions: {
    async submitSearch({ commit }, formData) {
      commit("SET_SEARCH_LOADING", true);
      try {
        const response = await axios.post(
          `${config.API_URL}/api/linkedin/searchProfiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const scoringProfiles = response.data?.scoring_profiles || [];

        const realData = scoringProfiles.map((m) => ({
          ...m,
          scoring:
            typeof m.scoring == "string" ? JSON.parse(m.scoring) : m.scoring,
        }));

        commit("SET_SEARCH_RESULTS", realData);
       
      } catch (error) {
        commit("SET_ERROR", error || "An error occurred");
      } finally {
        commit("SET_SEARCH_LOADING", false);
      }
    },
    setHistoryData({ commit }, data) {
      commit("SET_HISTORY_DATA", data);
    },
    setHistoryFilters({ commit }, filters) {
      commit("SET_HISTORY_FILTERS", filters);
    },
    setSearchError({ commit }, error) {
      commit("SET_ERROR", error);
    },
  },
};
