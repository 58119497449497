<template>
  <div class="d-flex flex-column align-start justify-space-around w-100">
    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
        </div>
        <div class="py-2 d-flex align-baseline justify-center">Processing</div>
      </v-snackbar>
    </v-overlay>

    <!-- main dialog box -->
    <v-dialog :value="show" max-width="800" persistent hide-overlay transition="dialog-bottom-transition" class="rounded-0 pa-2">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Enter Job Description</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <!-- <v-btn small icon dark @click="load_sample">
              <v-icon small>mdi-cloud-download</v-icon>
            </v-btn> 
            <v-btn small icon dark @click="get_parsed_jobs">
              <v-icon small>mdi-check</v-icon>
            </v-btn>
            
            -->

            <v-btn color="white" style="text-transform: capitalize" class="mr-2 black--text" @click="load_sample" small elevation="2">
              <v-icon class="mr-1" small>mdi-cloud-download</v-icon>
              Example
            </v-btn>
            <v-btn color="white" style="text-transform: capitalize" class="mr-2 black--text" @click="text = ''" small elevation="2">
              <v-icon class="mr-1" small>mdi-close-box-outline</v-icon>
              Clear
            </v-btn>

            <v-btn color="white" style="text-transform: capitalize" class="mr-2 black--text" @click="get_parsed_jobs" small elevation="2" :disabled="!text">
              <v-icon class="mr-1" small>mdi-check</v-icon>
              Start
            </v-btn>

            <v-btn small icon dark @click="$emit('job-parser-toggled', false)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <div class="w-100 pa-3">
          <div class="d-flex flex-column justify-center align-center w-100">
            <v-textarea v-model="text" label="Enter job description in natural langauge" class="small-font lc-input-fields rounded-0 mx-2" hide-details dense solo rows="18"> </v-textarea>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const { examples } = require("./examples");
export default {
  name: "JobParser",
  props: ["show"],
  data() {
    return {
      text: "",
      jobs: [],
      loading: false,
      example_index: -1,
    };
  },
  watch: {},
  async mounted() {
    try {
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    load_sample() {
      this.example_index++;
      if (this.example_index >= examples.length) this.example_index = 0;
      this.text = examples[this.example_index].trim();
    },
    async get_parsed_jobs() {
      try {
        // checking for other requests
        if (this.loading) return;
        if (!this.text) throw new Error("provide job description to continue");
        this.loading = true;

        const job_desc = this.text;
        const res = await this.axios.post("/api/pricing/getparsedjobs", { job_desc });
        this.loading = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.jobs = res.data.jobs;
        this.$emit("jobs-updated", this.jobs);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$emit("show-snack-bar", [true, "Error", "Jobs could not be parsed: check your internet connection"]);
      }
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.small-font {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}
::v-deep .v-text-field.v-text-field--solo .v-label {
  top: 10px !important;
}
</style>
