<template>
  <div class="d-flex flex-column align-end justify-space-around pb-1 w-100">
    <div v-for="(r, index) of roles" :key="index" class="d-flex flex-row align-center justify-space-around pb-1 w-100">
      <v-select v-model="r.role" label="Staff Role" :items="staff_roles" dense small-chips solo class="sr-input-fields mr-1" hide-details> </v-select>
      <v-text-field v-model="r.count" :min="1" label="count" type="number" class="small-font sr-input-fields" hide-details dense solo> </v-text-field>

      <v-icon color="red" dense small class="mx-1" style="cursor: pointer" @click="remove_range(index)">mdi-playlist-remove</v-icon>
      <v-icon v-if="index == 0" @click="add_range" color="primary" dense small class="mr-1" style="cursor: pointer">mdi-plus</v-icon>

      <div v-else class="mr-5"></div>
    </div>
  </div>
</template>

<script>
const { staff_roles } = require("./data");
export default {
  name: "StaffRoleSelector",
  props: ["roles"],
  data() {
    return {
      staff_roles: staff_roles,
      default_type: "standard",
    };
  },
  watch: {
    roles: {
      deep: true,
      handler(newval) {
        this.update_data();
      },
    },
  },
  mounted() {
    this.update_data();
  },
  methods: {
    update_data() {
      if (!this.roles.length) this.roles.push({ role: this.default_type, count: 1 });
    },
    add_range() {
      this.roles.push({ role: this.default_type, count: 1 });
    },
    remove_range(index) {
      if (this.roles.length > 1) this.roles.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .sr-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
</style>
