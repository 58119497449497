<template>
  <div class="d-flex flex-column align-start justify-space-around pb-1 w-100">
    <!-- Case: input type is text -->
    <div class="d-flex flex-row align-baseline justify-space-around pb-0 w-100">
      <v-text-field v-model="address_field" label="Choose location" class="small-font lc-input-fields rounded-1 mr-3" hide-details dense solo disabled>
        <v-icon slot="append" color="bssecondary2" dense small @click="show = true">mdi-arrow-right-bold-circle</v-icon>
        <v-tooltip color="bssecondary2" bottom slot="append">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
          </template>
          <span>Select location by clicking on the pin icon</span>
        </v-tooltip>
      </v-text-field>
      <div class="mx-4"></div>
    </div>

    <!-- main dialog box -->
    <v-dialog :value="show" max-width="600" persistent hide-overlay transition="dialog-bottom-transition" class="rounded-0 pa-2">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Select Location</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="select_location">
              <v-icon small>mdi-check</v-icon>
            </v-btn>
            <v-btn small icon dark @click="(show = false), (cities = [])">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <div class="w-100 pa-4">
          <div class="d-flex flex-column justify-start align-center w-100">
            <GmapAutocomplete :value="auto_complete_value" :options="autocomplete_options" @place_changed="set_place"></GmapAutocomplete>

            <GmapMap ref="mapRef" class="map-box" :zoom="map.zoom" :center="map.center" :options="map_options">
              <GmapMarker v-if="marker_position" :label="marker_label" :position="marker_position" />
            </GmapMap>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "LocationPicker",
  props: ["place"],
  data() {
    return {
      cities: [],
      include: true,
      address_field: null,
      selected_place: null,
      show: false,
      // place: null,
      map_options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      autocomplete_options: {
        componentRestrictions: {
          country: ["au"],
        },
      },
      auto_complete_value: "",
      map: {
        zoom: 10,
        center: { lat: -27.452922607070487, lng: 153.07158451563663 },
      },
      map_object: null,

      // marker position
      marker_position: null,
      marker_label: "★",
    };
  },
  watch: {
    place(newval) {
      this.load_place();
    },
  },
  async mounted() {
    try {
      this.load_place();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async set_place(place) {
      if (!place || !place.place_id) return;
      const position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      const city = this.extract_address_component(place.address_components, "administrative_area_level_2");
      const state = this.extract_address_component(place.address_components, "administrative_area_level_1");

      // setting field values
      this.selected_place = place;
      this.selected_place.position = position;
      this.selected_place.city = city;
      this.selected_place.state = state;

      this.auto_complete_value = this.selected_place.formatted_address;
      // this.address_field = this.selected_place.formatted_address;

      // setting map values
      this.marker_position = position;
      this.map.center = position;
      this.map.zoom = 17;
    },
    extract_address_component(address_components, type) {
      for (let component of address_components) {
        for (let component_type of component.types) {
          if (component_type == type) {
            return component.long_name;
          }
        }
      }
      return "";
    },
    select_location() {
      if (this.selected_place) {
        this.$emit("place-selection-updated", this.selected_place);
        this.show = false;
      }
    },

    // loading a place from history, triggered when a place is changed in parent
    load_place() {
      if (!this.place) {
        this.address_field = "";
        this.auto_complete_value = "";
      } else if (this.place && !this.place.place_id) {
        const p = this.place;
        this.address_field = p.formatted_address;
        this.auto_complete_value = p.formatted_address;

        this.marker_position = null;
        this.map.center = { lat: -27.452922607070487, lng: 153.07158451563663 };
        this.map.zoom = 17;
      } else {
        const p = this.place;
        this.address_field = p.formatted_address;
        this.auto_complete_value = p.formatted_address;

        this.marker_position = p.position;
        this.map.center = p.position;
        this.map.zoom = 17;
      }
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}
.pac-target-input,
.pac-target-input:focus-visible {
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 6px 12px;
  border-radius: 4px;
  border: none !important;
  background-color: #ffffff;

  font-weight: 500;
  font-size: 13px !important;
  margin-bottom: 10px;
}

.small-font {
  font-weight: 500;
  font-size: 13px !important;
}

.map-box {
  width: 100%;
  height: 400px;
  border-radius: 4px;
}
.pac-container::after {
  display: none !important;
  font-size: 40px;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
