<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  created() {
    let token = localStorage.getItem("token");
    // let email = localStorage.getItem("email");
    if (token && this.$route.name != "Home")
    {
      console.log("here");
      this.$router.push({ name: "Home" });
    }
    else if (!token && this.$route.name != "Login") {
      // if (!email) {
        this.$router.push({ name: "Login" });
      // } else {
        // this.$router.push({ name: "linkedinCred" });
      // }
    }
  },
};
</script>

<style></style>
