const axios = require("axios");
let existing_clients = [];

const awards = [
  { text: "Retail", value: "retail" },
  { text: "Miscellaneous", value: "misc" },
  { text: "Hospitality", value: "hosp" },
];

const tiers = [
  { text: "Tier 1", value: "t1" },
  { text: "Tier 2", value: "t2" },
  { text: "Non Profit", value: "nfp" },
  { text: "VIP", value: "vip" },
];

const client_types = [
  { text: "New", value: true },
  { text: "Existing", value: false },
];

const staff_roles = [
  { text: "Event Staff", value: "standard" },
  { text: "Brand Ambassador", value: "brand_ambassador" },
  { text: "Food & Beverage Staff", value: "food_beverage_staff" },
  { text: "Team Leader / Event Assistant", value: "team_leader" },
  { text: "Event Manager", value: "event_manager" },
  { text: "Mascot", value: "mascot" },
];

const cities = [
  { text: "Other", value: "other" },
  { text: "Sydney", value: "sydney" },
  { text: "Melbourne", value: "melbourne" },
  { text: "Brisbane", value: "brisbane" },
  { text: "Gold Coast", value: "gold coast" },
  { text: "Sunshine Coast", value: "sunshine coast" },
  { text: "Perth", value: "perth" },
  { text: "Adelaide", value: "adelaide" },
  { text: "Newcastle", value: "newcastle" },
  { text: "Central Coast", value: "central coast" },
  { text: "Wollongong", value: "wollongong" },
  { text: "Geelong", value: "geelong" },
  { text: "Bundaberg", value: "bundaberg" },
  { text: "Cairns", value: "cairns" },
  { text: "Coffs Harbour", value: "coffs harbour" },
  { text: "Forster", value: "forster" },
  { text: "Gladstone", value: "gladstone" },
  { text: "Hervey Bay", value: "hervey bay" },
  { text: "Mackay", value: "mackay" },
  { text: "Noosa", value: "noosa" },
  { text: "Port Macquarie", value: "port macquarie" },
  { text: "Rockhampton", value: "rockhampton" },
  { text: "Townsville", value: "townsville" },
  { text: "Bathurst", value: "bathurst" },
  { text: "Darwin", value: "darwin" },
  { text: "Hobart", value: "hobart" },
  { text: "Canberra", value: "canberra" },
];

const user_fields = {
  city: {
    value: "brisbane",
    include: true,
    show_include: false,
    label: "Select Job City or Area",
    info: "Select which city the job is located in, unfortunately Google Maps is does not keep record of Australian Cities. \n This is a workaround till proper solution for this is mapped",
    show_info: true,
    show: true,
    type: "text",
    input: "select",
    input_data: {
      items: cities,
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
      padding_right: true,
    },
  },
  award: {
    value: "retail",
    include: true,
    show_include: false,
    label: "Select Award",
    info: "Select award or category the job falls in",
    show_info: true,
    show: true,
    type: "text",
    input: "select",
    input_data: {
      items: awards,
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
      padding_right: true,
    },
  },
  exclude_breaks: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Setting this will ignore break rules and cost the invoice without breaks",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Exclude shift breaks? (Current = Yes)",
      label_off: "Exclude shift breaks? (Current = No)",
      default: false,
    },
  },
  exclude_travel: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Setting this will ignore all manual and automatic travel calculations",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Exclude travel time? (Current = Yes)",
      label_off: "Exclude travel time? (Current = No)",
      default: false,
    },
  },
  manual_travel: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Set manual travel time, this will ignore automatic travel cost calculation and conditions",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Set Manual Travel Time? (Current = Yes)",
      label_off: "Set Manual Travel Time? (Current = No)",
      default: false,
    },
  },
  manual_travel_time: {
    value: null,
    show: (fs) => fs.manual_travel.value,
    include: true,
    show_include: false,
    label: "Travel time per day (2-way in hours)",
    info: `Enter number of expected travel hours per staff per day for 2 way journey, <br> this will be used as it is overriding the rules for minimum distance and time`,
    show_info: true,
    type: "number",
    input: "text",
    input_data: {
      default: null,
      padding_right: true,
    },
  },
  within_shift_training: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Is there a requirement that staff will have to take training in-person prior to the job on the day of their shift? This time will be added to the start of each shift.",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Training required in-person on the day of their shift? (Current = Yes)",
      label_off: "Training required in-person on the day of their shift? (Current = No)",
      default: false,
    },
    on_change: (fs, v) => {
      if (v) fs.inperson_training.value = fs.online_training.value = false;
    },
  },
  inperson_training: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Is there a requirement that staff will have to take training in-person prior to the job? \nFor training requirements between shift hours, add that time in the shift directly in date & time selection.",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Separate In-person training required? (Current = Yes)",
      label_off: "Separate In-person training required? (Current = No)",
      default: false,
    },
    on_change: (fs, v) => {
      if (v) fs.within_shift_training.value = fs.online_training.value = false;
    },
  },
  online_training: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Is there a requirement that staff will have to take online training prior to the job?",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Online training required? (Current = Yes)",
      label_off: "Online training required? (Current = No)",
      default: false,
    },
    on_change: (fs, v) => {
      if (v) fs.within_shift_training.value = fs.inperson_training.value = false;
    },
  },
  training_hrs: {
    value: null,
    show: (fs) => fs.inperson_training.value || fs.online_training.value || fs.within_shift_training.value,
    include: true,
    show_include: false,
    label: "Training hours",
    info: `Enter number of expected training hours per staff`,
    show_info: true,
    type: "number",
    input: "text",
    input_data: {
      default: null,
      padding_right: true,
    },
  },
  leadgen_or_kpi: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are the staff doing lead gen or being given KPI’s to reach?",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Lead generation or KPI’s to reach? (Current = Yes)",
      label_off: "Lead generation or KPI’s to reach? (Current = No)",
      default: false,
    },
  },
  vehicle_to_reach: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are staff members required use their own vehicle to take anything to/from the shift? ",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Requires personal vehile to reach? (Current = Yes)",
      label_off: "Requires personal vehile to reach? (Current = No)",
      default: false,
    },
  },
  vehicle_for_shift: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are staff members required to use their own vehicle in order to complete the shift? ",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Requires personal vehile within shift? (Current = Yes, Default = 100kms)",
      label_off: "Requires personal vehile within shift? (Current = No)",
      default: false,
    },
    on_change: (fs, v) => {
      if (v) fs.vehicle_to_reach.value = true;
    },
  },
  vehicle_count: {
    value: null,
    show: (fs) => fs.vehicle_to_reach.value || fs.vehicle_for_shift.value,
    include: true,
    show_include: false,
    label: "Vehicle count",
    info: `Enter total number of vehicles required for the job`,
    show_info: true,
    type: "number",
    input: "text",
    input_data: {
      default: 4,
      padding_right: true,
    },
  },
  vehicle_kms_within_shift: {
    value: null,
    show: (fs) => fs.vehicle_for_shift.value,
    include: true,
    show_include: false,
    label: "Distance in KMs",
    info: `Enter estimated distance in kiliometers that a vehicle has to travel in order to complete the shift (this is separate from distance required to reach shift location)`,
    show_info: true,
    type: "number",
    input: "text",
    input_data: {
      default: 100,
      padding_right: true,
    },
  },
  airport_location: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Is the location an airport?  ",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Airport location? (Current = Yes)",
      label_off: "Airport location? (Current = No)",
      default: false,
    },
  },
  accommodation: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are the staff staying overnight in accommodation? ",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Accommodation overnight? (Current = Yes)",
      label_off: "Accommodation overnight? (Current = No)",
      default: false,
    },
  },
  uniforms: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are staff required to take home uniforms and wash them?",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Uniform upkeep? (Current = Yes)",
      label_off: "Uniform upkeep?  (Current = No)",
      default: false,
    },
  },
  certificates: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Does staff require a 'Working With Children Check' or a certificate like 'Responsible Service of Alcohol', 'Food Handling' or 'Food Safety Supervisor'?",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Certification Required? (Current = Yes)",
      label_off: "Certification Required?  (Current = No)",
      default: false,
    },
  },
  online_induction: {
    value: false,
    show: true,
    include: true,
    show_include: false,
    label: "",
    info: "Are staff required to complete an online induction that takes longer than 10 mins to complete? ",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Long Online induction? (Current = Yes)",
      label_off: "Long Online induction?  (Current = No)",
      default: false,
    },
  },
};
const system_fields = {
  new_client: {
    value: false,
    include: true,
    show_include: false,
    label: "Select Client type",
    info: "Select client is new or an existing one",
    show_info: true,
    show: true,
    type: "boolean",
    input: "select",
    input_data: {
      items: client_types,
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
    on_change: (fs, v) => (fs.client_name.value = fs.tier.value = fs.client_id.value = null),
  },
  existing_client: {
    value: "",
    show: (fs) => fs.new_client.value == false,
    include: true,
    show_include: false,
    label: "Select Existing Client",
    info: "Select the client you want to calculate cost for",
    show_info: true,
    type: "text",
    input: "autocomplete",
    input_data: {
      items: existing_clients,
      item_text: (v) => `${v.text} (${v.value ? v.value.toUpperCase() : "none"})`,
      item_value: "id",
      default: "",
      multiple: false,
      return_object: true,
      initialize_items: async () => {
        try {
          const res = await axios.get("/api/pricing/getclients");
          return res.data.clients;
        } catch (error) {
          console.log(error.message ? error.message : error);
        }
      },
    },
    on_change: (fs, v) => {
      if (!v) {
        fs.client_name.value = fs.tier.value = fs.client_id.value = null;
      } else {
        fs.client_name.value = v.text;
        fs.tier.value = v.value;
        fs.client_id.value = v.id;
      }
    },
  },
  client_name: {
    value: "",
    // show: (fs) => fs.new_client.value == true,
    show: true,
    include: true,
    show_include: false,
    label: "Clients Name",
    info: "Enter clients name for history and record purpose",
    show_info: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  client_id: {
    value: null,
    show: false,
    include: true,
    show_include: false,
    label: "Clients Name",
    info: "Enter clients name for history and record purpose",
    show_info: true,
    type: "boolean",
    input: "checkbox",
    input_data: {
      default: null,
    },
  },
  tier: {
    value: "t1",
    include: true,
    show_include: false,
    label: "Select Tier",
    info: "Select Tier applied to the client",
    show_info: true,
    show: true,
    type: "text",
    input: "select",
    input_data: {
      items: tiers,
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },
  gst: {
    value: null,
    show: true,
    include: true,
    show_include: false,
    label: "GST (default = 10%)",
    info: `Enter applied GST in percentage (default = 10%)`,
    show_info: true,
    type: "number",
    input: "text",
    input_data: {
      default: null,
      padding_right: false,
    },
  },
  add_management_fee_discount: {
    value: false,
    show: (fs) => fs.new_client.value,
    include: true,
    show_include: false,
    label: "",
    info: "Checking this box will add a discount of management fee for new clients only, no discount will be added by default.",
    show_info: true,
    type: "text",
    input: "checkbox",
    input_data: {
      label_on: "Add New Client Management Fee Discount? (Current = Yes)",
      label_off: "Add New Client Management Fee Discount? (Current = No)",
      default: false,
    },
  },

};

module.exports = {
  user_fields,
  system_fields,
  staff_roles,
  cities,
};

/*
----- sample fields format -----
  theme: {
    value: "",
    label: "Title or Theme of title",
    info: `Info text about the field`,
    show_info: true,
    include: true,
    show: true,
    show_include: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
words: {
    value: [200, 400],
    label: "Words",
    info: "Estimated length in words",
    show_info: true,
    include: true,
    show: true,
    show_include: true,
    type: "text",
    input: "range_slider",
    input_data: {
      min: 100,
      max: 800,
      step: 50,
      default: [200, 400],
    },
  },
  variety: {
    value: 5,
    label: "Variety",
    info: "How regularly Gerald pulls out his thesaurus to find different words to use in a sentence",
    show_info: true,
    include: true,
    show: true,
    show_include: true,
    type: "text",
    input: "slider",
    input_data: {
      min: 0,
      max: 10,
      step: 0.5,
      append: "/10",
      default: 5,
    },
  },

  tones: {
    value: [],
    label: "Select tone",
    info: "Select upto 4 tones from the dropdown menu to be used for article generation. This will set the tone/mood for the article",
    show_info: true,
    include: true,
    show: true,
    show_include: false,
    type: "text",
    input: "combobox",
    input_data: {
      items: [],
      item_text: "tone",
      item_value: "tone",
      default: [],
    },
  },
    seo_keywords: {
    value: true,
    label: "",
    info: "Include keywords for SEO or not",
    show_info: true,
    include: true,
    show: true,
    show_include: false,
    type: "text",
    input: "switch",
    input_data: {
      label_on: "Include enough keywords for SEO",
      label_off: "Don't Include enough keywords for SEO",
      default: true,
    },
  },
 */
