const { saveAs } = require("file-saver");
const { Document, Packer, Paragraph, TextRun } = require("docx");
const XLSX = require("xlsx");

const download_doc = function (content, filename) {
  try {
    let text = content
      .trim()
      .split("\n")
      .map(
        (line) =>
          new TextRun({
            break: 1.5,
            text: line,
            font: "Tahoma",
          })
      );
    // preparing docx file
    const doc = new Document({
      sections: [
        {
          children: [new Paragraph({ children: text })],
        },
      ],
    });

    // saving file

    let file_name = filename ? filename : "File";
    Packer.toBlob(doc).then((blob) => saveAs(blob, `${file_name}.docx`));
  } catch (error) {
    console.log("An error occured while creating doc file");
  }
};

const download_excel = function (data, filename) {
  // data sanitization & prepration

  // create a workbook
  let wb = XLSX.utils.book_new();

  // create a worksheet
  let ws = XLSX.utils.json_to_sheet(data);
  ws["!cols"] = [{ width: 60, font: { bold: true } }, { width: 100, wrapText: true }, { width: 50 }];

  // set the first row to bold
  // ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
  // let cell = ws[XLSX.utils.encode_cell({ r: 0, c: 0 })];
  // cell.s = { font: { bold: true } };

  // add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // generate the Excel file
  let wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // save the file
  filename += ".xlsx";
  let blob = new Blob([wbout], { type: "application/octet-stream" });
  saveAs(blob, filename);
};

module.exports.download_doc = download_doc;
module.exports.download_excel = download_excel;
