import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#000000", // Modify the primary color
        secondary: "#ffffff", // Modify the secondary color
        accent: "#0000FF", // Modify the accent color
        error: "#FF0000", // Modify the error color
        // Add or modify other color values as needed

        bstopbar: "#ffffff",
        bssidebar: "#000000",
        bstopbarfont: "pink",
        bssidebarfont: "#ffffff",

        bsprimary1: "#ffffff",
        bssecondary1: "#000000",

        bsprimary2: "#ffffff",
        bssecondary2: "#000000",

        bssuccess: "#4973bd", //blue
        bsdanger: "#ed5b72",
        bserror: "#ed5b72",

        // extras
        bssuccessbtns: "#00e2a4",
        bssdangerbtns: "#ff6bc5",
        // bssdangerbtns: "#fdd4ed",

        bd1: "#00E2A4", // Teal
        bd2: "#FDD4ED", // Rose Quartz
        bd3: "#26337A", // Navy
      },
    },
  },
});
