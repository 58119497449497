const examples = [
  // example1
  `We're organizing two events, requested by our Tier 2 client, Andrew. The first event, targeting the 'Sustainability Champion' award, requires 4 standard staff and 2 mascots at Urban Greenspace, Brisbane, Queensland, on May 12th and 14th from 8 AM to 2 PM. Staff need to complete a 3-hour online training on EcoTech's sustainable products.

    The second event at Tech Expo, Innovation Hub, Newcastle, New South Wales, from May 18th to 21st, from 10 AM to 4 PM, requires 2 standard staff for B2B interactions. A 1-hour online induction is provided. Transportation and EcoTech-branded uniforms will be supplied, but no accommodation is provided. Andrew emphasizes the importance of staff engagement and knowledge about EcoTech's sustainability goals.`,

  // example2
  `In the upcoming month, we are excited to launch two distinct marketing campaigns, each requiring a unique set of skills and personnel. For the first campaign, we need 3 standard staff and 1 mascot to promote our latest product line at Plaza Central, 789 Retail Rd, Market City, Queensland 4700, Australia, from 9 AM to 3 PM on May 15th. This initiative is aimed at introducing 'Innovative Tech Solutions', a new player in the tech gadget sector, to the Market City community. Prior to the campaign, staff are required to attend a 2-hour in-person training session to familiarize themselves with the product features and campaign goals.

The second campaign is scheduled for May 20th, running from 10 AM to 4 PM at the Green Park Conference Center, 321 Conference Blvd, Summit Town, Queensland 4800, Australia. We're seeking 2 standard staff members to assist in a B2B networking event for 'Green Future Innovations', a company dedicated to sustainable business practices. This sample_job will cater to existing clients within the sustainability sector and aims to foster new partnerships. The selected staff should complete an online induction course highlighting the company's vision and operational guidelines.

Both jobs emphasize the importance of engaging effectively with attendees and require reliable transportation to and from the event locations. No accommodation will be provided, but all necessary uniforms and identification badges will be supplied. Please note that while no specific certificates are required for these roles, a proactive and communicative approach is essential for success.`,

  // example3
  `This month, we have the privilege of orchestrating two promotional campaigns for 'EcoTech Innovations', a pioneering company in the green technology sector. Our Tier 2 client, Andrew, has requested a detailed quote for these events, emphasizing the importance of aligning with EcoTech Innovation's prestigious 'Sustainability Champion' award standards.

The first campaign will unfold at Urban Greenspace, 101 Nature Rd, Brisbane City, Queensland 4000, Australia, scheduled for May 12th and May 14th, from 8 AM to 2 PM each day. This segmented approach aims to maximize engagement by targeting weekend crowds. For this campaign, we require 4 standard staff members and 2 mascots to actively engage visitors and educate them about EcoTech Innovations' latest sustainable living products. Staff are expected to undergo a 3-hour online training session prior to the event to ensure they are well-versed in product knowledge and the company's sustainability goals.

Our second promotional activity is set for the Tech Expo at Innovation Hub, 202 Tech Park Dr, Newcastle, New South Wales 2300, Australia, taking place on May 18th, from 10 AM to 4 PM. This one-day intensive requires 2 standard staff to manage the EcoTech Innovations booth, focusing on business-to-business interactions and product demonstrations for an audience primarily comprised of industry professionals. A brief, 1-hour online induction covering the Expo's layout, expected visitor demographics, and key talking points about EcoTech Innovations' role in the technology sector will be provided.

For both campaigns, EcoTech Innovations emphasizes the need for staff to be highly proactive, engaging, and knowledgeable about the brand and its products. Transportation to the venues will be arranged for all staff members, and EcoTech Innovations will supply branded uniforms and all necessary promotional materials. Please note, no accommodation is provided, and while specific certificates are not required for participation, a commitment to embodying the company's values of innovation and sustainability is crucial.

Andrew has highlighted that these campaigns are critical to EcoTech Innovations' marketing strategy, aiming to solidify its position as a leader in sustainable technology solutions.`,
];

module.exports = { examples };
