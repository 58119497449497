<template>
  <div class="d-flex flex-column align-end justify-space-around pb-1 w-100">
    <div v-for="(range, index) of ranges" :key="index" class="d-flex flex-row align-baseline justify-start pb-1 w-100">
      <date-picker
        v-model="range.dates"
        type="date"
        range
        :disabled-date="notBeforeToday"
        placeholder="Date Range"
        clearable
        format="YYYY-MM-DD"
        value-type="YYYY-MM-DD"
        class="mr-1 dt-fields"
      ></date-picker>

      <!-- <date-picker v-model="range.time.start" format="hh:mm a" value-type="format" type="time" :minute-step="min_step" placeholder="Start Time" clearable></date-picker>
      <date-picker v-model="range.time.end" format="hh:mm a" value-type="format" type="time" :minute-step="min_step" placeholder="End Time" clearable></date-picker> -->

      <date-picker v-model="range.time" range format="hh:mm a" value-type="format" type="time" :minute-step="min_step" placeholder="Select Time" class="dt-fields" clearable></date-picker>
      <v-icon color="red" dense small class="mx-1" style="cursor: pointer" @click="remove_range(index)">mdi-playlist-remove</v-icon>

      <v-icon v-if="index == 0" @click="add_range" color="primary" dense small class="mr-1" style="cursor: pointer">mdi-plus</v-icon>
      <div v-else class="mr-5"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ["ranges"],
  components: { DatePicker },
  data: () => {
    return {
      min_step: 15,
      // ranges: [],
      format: "hh:mm a",
    };
  },
  watch: {
    ranges: {
      deep: true,
      handler(newval) {
        this.update_data();
      },
    },
  },
  mounted() {
    try {
      this.update_data();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    update_data() {
      if (!this.ranges.length) {
        const start = moment().add(1, "day").format("YYYY-MM-DD");
        const end = moment().add(2, "day").format("YYYY-MM-DD");
        this.ranges.push({ dates: [start, end], time: ["09:00 am", "05:00 pm"] });
      }
    },
    add_range() {
      this.ranges.push({ dates: [null, null], time: [null, null] });
    },
    remove_range(index) {
      if (this.ranges.length > 1) this.ranges.splice(index, 1);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>

<style>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
.small-font,
.mx-input {
  font-weight: 500;
  font-size: 13px !important;
}
.w-100 {
  width: 100%;
  max-width: 100% !important;
}
.ctrl-btn {
  text-transform: initial;
}
.dt-fields input {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5em;
}
.dt-fields {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
</style>
