<template>
  <v-row
    class="d-flex flex-row justify-space-between align-start ma-0 pa-0"
    align="stretch"
  >
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="3000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-col cols="12" class="pa-0 d-flex">
      <v-sheet
        color="white"
        elevation="3"
        rounded
        class="mr-md-1 pb-3 mx-0 column-content w-100"
        style="min-height: 90vh"
      >
        <v-row class="px-2 mt-6 pb-0 justify-space-between" no-gutters>
          <v-col
            cols="12"
            md="12"
            class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
              height="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">Filter History</h5>
              </div>
              <v-form @submit.prevent="submitFilter" ref="form">
                <div class="d-flex justify-space-between align-items-center">
                  <v-col cols="5" class="pa-0">
                    <v-menu
                      ref="menu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="historyFilters.createdAt"
                          label="Date Created"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="historyFilters.createdAt"
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="5" class="pa-0">
                    <v-text-field
                      v-model="historyFilters.searchName"
                      label="Search Name"
                      class="small-font lc-input-fields"
                      dense
                      solo
                    >
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="bssecondary2"
                            dense
                            small
                            v-bind="attrs"
                            v-on="on"
                            >mdi-information-variant</v-icon
                          >
                        </template>
                        <span v-html="'Give this search a name!'"></span>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-btn
                      type="submit"
                      class="my-1 ml-3"
                      color="primary"
                      small
                      elevation="2"
                      style="text-transform: capitalize"
                    >
                      <v-icon class="mr-1" small>mdi-filter</v-icon>Filter
                    </v-btn>
                    <v-btn
                      @click="clearFilter"
                      class="my-1 ml-3"
                      color="white"
                      small
                      elevation="2"
                      style="text-transform: capitalize"
                    >
                      <v-icon class="mr-1" small>mdi-close</v-icon>Clear
                    </v-btn>
                  </v-col>
                </div>
              </v-form>
            </v-sheet>
          </v-col>

          <v-col cols="12" md="12" class="mr-0 pr-0 py-0 settings-div">
            <v-sheet
              color="white"
              elevation="3"
              rounded
              class="d-flex align-start justify-center mt-2"
            >
              <v-data-table
                :headers="headers"
                :items="transformedHistoryData"
                item-key="id"
                class="px-3 py-4 fill-height w-100"
                dense
                :footer-props="{
                  'items-per-page-options': [20, 30, 50, 100, 250],
                }"
                @click:row="redirectToScoringPage"
              >
                <template v-slot:top>
                  <v-row class="px-4 py-1 align-baseline justify-center">
                    <v-col cols="12" md="12" class="mx-0 px-0 mr-0 py-0">
                      <h4
                        class="my-2 mx-0 px-0 text-left pr-2 w-100 d-flex flex-start align-baseline"
                      >
                        <span>History</span>
                      </h4>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item="{ item }">
                  <tr class="history-row" @click="redirectToScoringPage(item)">
                    <td>{{ item.sr }}</td>
                    <td>{{ item.searchName }}</td>
                    <td><a :href="item.searchUrl" target="_blank">Link</a></td>
                    <td>{{ item.createdAt }}</td>
                  </tr>
                </template>
                <template v-slot:no-data>
                  {{
                    isContentLoading ? "Loading data..." : "No records found"
                  }}
                </template>
              </v-data-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import config from "../../../../config/app.json";

export default {
  data() {
    return {
      headers: [
        { text: "SR#", value: "sr" },
        { text: "Search Name", value: "searchName" },
        { text: "Search URL", value: "searchUrl" },
        { text: "Date Created", value: "createdAt" },
      ],
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      dateMenu: false,
      isContentLoading: false,
    };
  },
  computed: {
    ...mapState({
      historyData: (state) => state.scorings.historyData,
      historyFilters: (state) => state.scorings.historyFilters,
    }),
    transformedHistoryData() {
      return this.historyData.map((item, index) => ({
        ...item,
        sr: index + 1,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
      }));
    },
  },
  mounted() {
    this.refreshHistory();
  },
  methods: {
    ...mapActions({
      setLoading: "setLoading",
      clearLoading: "clearLoading",
      setHistoryData: "setHistoryData",
      setHistoryFilter: "setHistoryFilter",
    }),

    async submitFilter() {
      await this.refreshHistory();
    },

    async refreshHistory() {
      this.isContentLoading = true;
      try {
        const response = await axios.get(
          `${config.API_URL}/api/linkedin/history`,
          {
            params: {
              userId: this.$store.getters.getUser.id,
              createdAt: this.historyFilters.createdAt,
              searchName: this.historyFilters.searchName,
            },
          }
        );

        this.setHistoryData(response.data.response);
        // this.snackbarShow(true, "History Refreshed Successfully");
      } catch (error) {
        this.snackbarShow(
          true,
          "Error",
          error.message || "Could not refresh history"
        );
      } finally {
        this.clearLoading();
        this.isContentLoading = false;
      }
    },

    async clearFilter() {
      this.historyFilters.createdAt = "";
      this.historyFilters.searchName = "";
      await this.refreshHistory();
    },
    redirectToScoringPage(item) {
      this.$router.push({
        name: "ScoringWithId",
        params: { id: item.id },
      });
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
      this.showSnackbarCloseButton = true;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

            margin: 0px !important;
        } */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bd1-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bd2-base) !important;
}

.tooltip-text {
  color: black;
}
.chatbot-settings-wrapper .v-btn {
  text-transform: capitalize;
  min-width: 200px;
  float: right;
}

.tabs-main {
  border-radius: 3px;
  margin-bottom: 5px;
}
.v-tabs .v-tab {
  color: #ffffff !important; /* Navy color */
}
.error-message {
  color: red;
  margin-top: 10px;
}
.submit-button {
  margin-top: 20px;
}

.login-button.success {
  background-color: green;
  color: white;
}
::v-deep .history-row {
  cursor: pointer;
}
</style>
