<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Kindly Provide Your Linkedin Credentials</h4>
    <v-text-field @keyup.enter="validate()" v-model="linkedinEmail" :rules="emailRules" label="Email" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="linkedinPassword" :rules="passwordRules" label="Password" required type="password" solo dense></v-text-field>

    <v-btn  class="my-4" color="primary" @click="validate()" :disabled="!valid || requestSent">
      Login
    </v-btn>
    <div class="red--text small-font">{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    linkedinPassword: "",
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Invalid Password"],
    linkedinEmail: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "Invalid E-mail"],

    valid: true,
    requestSent: false,
    status: "",
  }),

  methods: {
    validate() {
      this.status = "Logging in...";
      let validated = this.$refs.form.validate();

      if (!validated) {
        this.status = "Invalid Email or Password";
        return;
      }

      // sending request to api
      this.requestSent = true;
      this.axios
        .post("/api/users/linkedin", {
          linkedinEmail: this.linkedinEmail,
          linkedinPassword: this.linkedinPassword,
        })
        .then((res) => {
          this.requestSent = false;
          this.status = res.data.message;
          if (res.data.success) {
            localStorage.setItem("email",this.linkedinEmail);
         
            this.$router.push({ name: "Home" });
          }
          // else if (!res.data.success && res.data.redirect === "unverifiedUser") {
          //   this.$router.push({ name: "PendingVerification", params: { email: this.email } });
          // }
          // else if (!res.data.success && res.data.redirect === "unverifiedAdminUser") {
          //   this.$router.push({ name: "PendingAdminVerification" });
          // }
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "Could not log in - kindly check your internet connection";
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
