<template>
  <!-- View Layout -->
  <v-row
    class="d-flex flex-row justify-space-between align-start ma-0 pa-0"
    align="stretch"
  >
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="-1"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-col cols="12" class="pa-0 d-flex">
      <v-sheet
        color="white"
        elevation="3"
        rounded
        class="mr-md-1 pb-3 mx-0 column-content w-100"
        style="min-height: 90vh"
      >
        <v-row class="px-2 mt-6 pb-0 justify-space-between" no-gutters>
          <v-col
            cols="12"
            md="12"
            class="pl-2 pr-0 py-0 d-flex flex-column align-baseline justify-start"
          >
            <v-sheet
              color="bsprimary2"
              elevation="3"
              rounded
              class="pa-4 mb-2"
              width="100%"
              height="100%"
            >
              <div
                class="w-100 pt-0 d-flex flex-row align-baseline justify-center"
              >
                <h5 class="mb-4 text-left">LinkedIn Account Login</h5>
              </div>
              <v-form
                @submit.prevent="loginLinkedIn"
                ref="form"
                v-if="!isLoginLinkedin"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    class="small-font lc-input-fields"
                    required
                    solo
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    class="small-font lc-input-fields"
                    label="Password"
                    required
                    :type="showPassword ? 'text' : 'password'"
                    solo
                    dense
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="togglePasswordVisibility"
                  ></v-text-field>
                </div>

                <v-btn
                  type="submit"
                  :loading="isLoginLoading"
                  :disabled="isLoginLoading || isLoginLinkedin"
                  color="white"
                  class="login-button ml-3"
                  small
                  elevation="2"
                  v-bind:class="{ success: isLoginLinkedin }"
                  style="text-transform: capitalize"
                >
                  <v-icon class="mr-1" v-if="!isLoginLinkedin" small>
                    mdi-login
                  </v-icon>
                  Login
                  <v-icon v-if="isLoginLinkedin">mdi-check</v-icon>
                </v-btn>

                <div v-if="linkedInValidationError" class="error-message">
                  {{ linkedInValidationError }}
                </div>
              </v-form>

              <!-- Optionally, you can display a message or a different UI element when login is successful -->
              <div v-if="isLoginLinkedin">
                <p>Logged in successfully!</p>
                <!-- You can add any other message or UI component here -->
              </div>

              <v-form @submit.prevent="submitOTP" class="mt-3" ef="form">
                <v-text-field
                  v-if="isOTPRequired"
                  v-model="otp"
                  class="small-font lc-input-fields"
                  label="OTP CODE"
                  required
                  solo
                  dense
                ></v-text-field>
                <v-btn
                  v-if="isOTPRequired"
                  @click="submitOTP"
                  :loading="isOTPLoading"
                  style="text-transform: capitalize"
                  class="success-btns black--text"
                  small
                  elevation="2"
                >
                  Submit OTP
                </v-btn>
              </v-form>
              <v-col class="my-2">
                <p><b>Instructions:</b></p>
                <p>
                  Click on Test Connection button to check if you are still online
                </p>
                <!-- <ul>
                  <li>
                    Firstly, connect to the VNC server using the password
                    <b>remap.ai</b>
                  </li>
                  <li>
                    Upon successful connection to the server, click the "Start
                    Browser" button.
                  </li>
                  <li>Log in to your LinkedIn Sales Navigator account.</li>
                  <li>
                    After successfully logging in LinkedIn, click the "Redirect
                    to Search Profiles" button.
                  </li>
                </ul> -->
                <ul>
                  <li>
                    <b>Account Status:</b>
                    {{ isLoginLinkedin ? "Online" : "Offline" }}
                  </li>
                  <!-- <li><b>Acount Type:</b> Normal Profile</li> -->
                </ul>
              </v-col>
              <v-row class="my-2">
                <v-col cols="8" class="d-flex justify-left">
                  <v-btn
                    @click="startBrowser"
                    :loading="isOpenBrowserLoading"
                    :disabled="isOpenBrowserLoading"
                    color="white"
                    style="text-transform: capitalize"
                    class="mr-2"
                    medium
                    elevation="2"
                  >
                    Test Connection
                  </v-btn>
                  <v-btn
                    @click="redirectToScoringPage"
                    color="white"
                    style="text-transform: capitalize"
                    medium
                    elevation="2"
                  >
                    Log Out
                    <v-icon class="ml-1">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="d-flex justify-center"> </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>

        <!-- <iframe :src="noVNCUrl" width="100%" height="600px"></iframe> -->
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import config from "../../../../config/app.json";

export default {
  data() {
    return {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Invalid Password",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "Invalid E-mail",
      ],

      isLoginLinkedin: localStorage.getItem("isLoginLinkedin"),
      isLoginLoading: false,
      noVNCUrl: "https://vnc.torri.remap.ai/vnc.html",
      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      linkedInValidationError: "",
      email: "",
      password: "",
      showPassword: false,
      otp: "",
      sessionData: "",
      url: "",
      isOTPRequired: false,
      isOTPLoading: false,
      isOpenBrowserLoading: false,
      st: "",
    };
  },
  computed: {
    ...mapState({
      getUser: (state) => state.users.user,
    }),
  },
  mounted() {
    if (this.isLoginLinkedin) {
      this.startBrowser();
    }
    
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    async checkLinkedinLogin() {
      try {
        const response = await axios.get(
          `${config.API_URL}/api/linkedin/authorized`
        );
        if (response.status === 200) {
          this.isLoginLinkedin = true;
        }
      } catch (error) {
        console.error("API error on render:", error);
      }
    },

    async loginLinkedIn() {
      this.isLoginLoading = true;
      let validated = this.$refs.form.validate();

      if (!validated) {
        this.linkedInValidationError = "Invalid Email or Password";
        this.isLoginLoading = false;
        return;
      }

      const formData = `email=${encodeURIComponent(
        this.email
      )}&password=${encodeURIComponent(this.password)}`;

      try {
        const response = await axios.post(
          `${config.API_URL}/api/linkedin/login`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            maxRedirects: 0, // Prevent Axios from automatically following redirects
          }
        );

        if (response.status === 200) {
          console.log("200");
          // Handle OTP or session data if required
          if (
            response.data?.response.status === "OTP required" ||
            response.data?.response?.sessionData
          ) {
            console.log("inner 200");
            this.isOTPRequired = true;
            this.isLoginLinkedin = false;
            this.sessionData = response.data?.response?.sessionData;
            this.url = response.data?.response?.url;
            this.snackbarShow(true, "Check Your Email and give Linkedin OTP");
          } else if (response.data?.response.status === "Captcha detected") {
            this.snackbarShow(true, "Captcha detected. Kindly log out from all browsers, then try logging in again. You will encounter the captcha; please resolve it and try again.");

          } else {
            console.log(" ELSE inner 200");

            // Successful login without OTP requirement
            this.isLoginLinkedin = true;
            localStorage.setItem("isLoginLinkedin", true);
            this.snackbarShow(true, "LinkedIn Login Successfully");
          }
        }
      } catch (error) {
        console.log("OUTERA ERROR 200");
        // Check if the error is a response from the backend
        if (error.response) {
          console.log("OUTERA INNER 200");
          // Handle 401 and other known errors
          if (error.response.status === 401) {
            console.log("  OUTERA INNER INNER 200");
            console.log(error.response)
            if (error.response.data.response.status.includes("Unable to locate element")) {
              this.snackbarShow(true, "Captcha detected. Kindly log out from all browsers, then try logging in again. You will encounter the captcha; please resolve it and try again.");

            } else if (error.response.data.response.status.includes("while clicking login open your linked")) {
              
              this.snackbarShow(true, "First, open the LinkedIn app on your mobile and click 'Login'; as soon as the popup appears, quickly click 'I recognize this device.");

             }
            else if (error.response.data.response.status.includes("disable phone number authentication")) { 
              this.snackbarShow(true, "Before logging in again, please disable 2FA (e.g., phone number authentication, authenticator app authentication) and turn off all related security features.");
            } else if (error.response.data.response.status.includes("disable authenticator app")) {
              this.snackbarShow(true, "Before logging in again, please disable 2FA (e.g., phone number authentication, authenticator app authentication) and turn off all related security features.");
            } else {
              this.snackbarShow(
              true,
              "Error",
              "Your LinkedIn credentials are incorrect"
            );
            }
           
          } else if (error.response.status === 500) {
            console.log("heeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            // Handle internal server errors (without redirecting)
            // Check if the response contains a URL for redirection (like LinkedIn login page)
            const redirectUrl = error.response.data?.redirectUrl;
            if (redirectUrl) {
              // If a redirect URL exists in the error response, ignore it
              // Don't redirect, just show an error message
              console.log("  OUTERA INNER INNER 200 REDIRECT");
              this.snackbarShow(
                true,
                "Error",
                "There was an internal server error. Please try again."
              );
            } else {
              console.log("  OUTERA INNER INNER 200 REDIRECT NO");

              // Handle the case where no redirect URL is present
              this.snackbarShow(
                true,
                "Error",
                "An unexpected error occurred. Please try again."
              );
            }
          } else {
            // Other error codes
            this.snackbarShow(
              true,
              "Error",
              error.message || "There was an unexpected error."
            );
          }
        } else {
          // If there's no response (network error, timeout, etc.)
          this.snackbarShow(
            true,
            "Error",
            "Network error. Please check your connection."
          );
        }
      } finally {
        this.isLoginLoading = false;
      }
    },

    async startBrowser() {
      try {
        this.isOpenBrowserLoading = true;
        const response = await axios.get(
          `${config.API_URL}/api/linkedin/startBrowser`
        );
        if (response.status == 200 && response.data.isOpenBrowserLoading) {
          this.isOTPLoading = false;
          this.isOTPRequired = false;
          localStorage.setItem("isLoginLinkedin", true);
          this.snackbarShow(true, "connection is stable");
        } else {
          this.isLoginLinkedin = false;
          localStorage.setItem("isLoginLinkedin", false);
          this.snackbarShow(true, "Kindly enter your credentials again");
        }
      } catch (err) {
        console.log("Error on Start borwser API:", err);
        this.snackbarShow(true, "Error", "Browser not open");
      } finally {
        this.isOpenBrowserLoading = false;
      }
    },
    async submitOTP() {
      console.log(this.sessionData);
      this.isOTPLoading = true;

      const formData = `otp=${encodeURIComponent(
        this.otp
      )}&sessionId=${encodeURIComponent(
        this.sessionData
      )}&url=${encodeURIComponent(this.url)}`;

      try {
        const response = await axios.post(
          `${config.API_URL}/api/linkedin/submitOTP`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (response.status === 200) {

          console.log(response)
          if(response.data.response.status.includes("Login successfu")){
          this.isOTPLoading = false;
          this.isOTPRequired = false;
          this.isLoginLinkedin=true
          localStorage.setItem("isLoginLinkedin", true);
            this.snackbarShow(true, "Linkedin Login Successfully");
          } 
        }
      } catch (error) {
        if (error.response.status === 401) {
          if (
            error.response?.data?.response?.status.includes("Page load failed")
          ) {
            this.snackbarShow(
              true,
              "Error",
              "Kindly login again OTP session expired"
            );
          } if (error.response.data.response.status.includes("Unable to locate element")) {
              this.snackbarShow(true, "Captcha detected. Kindly log out from all browsers, then try logging in again. You will encounter the captcha; please resolve it and try again.");

            } else {
            this.snackbarShow(true, "Error", "Your OTP is incorrect");
          }
        } else {
          console.error("Error:", error);
          this.snackbarShow(
            true,
            "Error",
            error.message
              ? error.message
              : "Could not Login Linkedin, try refreshing the page and starting again"
          );
        }
      } finally {
        this.isOTPLoading = false;
      }
    },

    async redirectToScoringPage() {
      // closed Browser
      try {
        const response = await axios.post(
          `${config.API_URL}/api/linkedin/user-logout`
        );
        if (response.status === 200) {
          this.isOTPLoading = false;
          this.isOTPRequired = false;
          this.isLoginLinkedin = false;
          localStorage.setItem("isLoginLinkedin", false);
          this.snackbarShow(true, "Linkedin Logged Out Successfully");
        }
        // this.$router.push({
        //   name: "Scoring",
        // });
      } catch (error) {
        this.snackbarShow(
          true,
          "Error",
          error.message ? error.message : "Could not logged out user"
        );
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
      this.showSnackbarCloseButton = true;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}

.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bd1-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bd2-base) !important;
}

.tooltip-text {
  color: black;
}
.chatbot-settings-wrapper .v-btn {
  text-transform: capitalize;
  min-width: 200px;
  float: right;
}

.tabs-main {
  border-radius: 3px;
  margin-bottom: 5px;
}
.v-tabs .v-tab {
  color: #ffffff !important; /* Navy color */
}
.error-message {
  color: red;
  margin-top: 10px;
}
.submit-button {
  margin-top: 20px;
}

.login-button.success {
  background-color: green;
  color: white;
}
</style>
