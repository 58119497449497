<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Register</h4>
    <v-text-field @keyup.enter="validate()" v-model="firstName"  label="First Name" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="lastName"  label="Last Name" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="assigned_number"  label="Mobile Number" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="email" :rules="emailRules" label="Email" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="password" :rules="passwordRules" label="Password" required type="password" solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="repeatPassword" :rules="passwordRules" label="Confirm Password" required type="password" solo dense></v-text-field>

    <v-btn  class="my-4" color="primary" @click="validate()" :disabled="!valid || requestSent">
      Register
    </v-btn>
    <div class="red--text small-font">{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    password: "",
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Invalid Password"],
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "Invalid E-mail"],
    firstName: "",
    lastName: "",
    assigned_number: "",
    repeatPassword: "",
    adminVerified:true,
    valid: true,
    requestSent: false,
    status: "",
  }),

  methods: {
    validate() {
      this.status = "Registering in...";
      let validated = this.$refs.form.validate();

      if (!validated) {
        this.status = "Kindly fill all fields";
        return;
      }

      // sending request to api
      this.requestSent = true;
      this.axios
        .post("/api/users/adminregister", {
          adminVerified: true,
          assigned_number: this.assigned_number,email: this.email,firstName: this.firstName,lastName: this.lastName,password: this.password,repeatPassword: this.repeatPassword,role: "user"
        })
        .then((res) => {
          this.requestSent = false;
          this.status = res.data.message;
          if (res.data.newUser.id) {
          
            this.$router.push({ name: "Login" });
         }
 
        
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "Could not log in - kindly check your internet connection";
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
