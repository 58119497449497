<template>
  <v-simple-table>
    <thead>
      <tr>
        <th class="label-column">Label</th>
        <th class="value-column">Score</th>
        <th class="value-column">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(value, key) in data" :key="key">
        <td :class="{ nested: nested }" class="label-column">
          <strong>{{ formatLabel(key) }}:</strong>
        </td>
        <td class="value-column">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ value.score }}
                <!-- Display the score -->
              </div>
            </template>
            <span>{{ value.remarks }}</span>
            <!-- Tooltip text containing the remarks -->
          </v-tooltip>
        </td>
        <td :class="{ nested: nested }" class="label-column">
          <strong>{{ value.details }}</strong>
        </td>
      </tr>
      <tr v-if="nested" :class="{ nestedRow: nested }">
        <td class="nested-total label-column"><strong>Total</strong></td>
        <td class="nested-total value-column">{{ sectionTotal }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "ScoreDetails",
  props: {
    data: {
      type: Object,
      required: true,
    },
    criteriaSwitches: {
      type: Array,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sectionTotal() {
      return `${this.calculateTotal(this.data)} / ${
        Object.keys(this.data).filter((f) => !f.includes("remarks")).length * 10
      }`;
    },
  },
  methods: {
    isObject(value) {
      return typeof value === "object" && value !== null;
    },
    formatLabel(label) {
      const criteria =
        this.criteriaSwitches &&
        this.criteriaSwitches.find((f) => f.key === label);
      if (criteria) {
        return criteria.label;
      }

      return label
        .replace(/_/g, " ")
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },
    calculateTotal(data) {
      return Object.values(data).reduce((sum, value) => {
        return sum + (Number(value.score) || 0);
      }, 0);
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
  color: black;
}
.fixed-width {
  width: 200px; /* Set a fixed width for the label column */
}
.label-column {
  text-align: left;
  width: 33%;
}

.value-column {
  text-align: left;
  width: 33%; /* Allow value column to take up the remaining space */
}

.nested {
  padding-left: 20px;
  color: #a5a5a5;
}

.nested-total {
  padding-left: 20px;
  color: #a5a5a5;
  font-weight: bold;
}

.v-simple-table {
  border: 1px solid #4b414124;
}

::v-deep th.text-start {
  min-width: 100px;
}

::v-deep th {
  font-weight: 800;
  text-align: left;
}

::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
  text-align: left;
}
</style>
